<template>
  <Layout>
    <PageHeader
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        :title="$t('branch_sites.title')"
    />

    <button
        @click="addSite"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#add-account"
        class="btn btn-primary mb-4 float-end"
    >
      {{ $t("branch_sites.add") }}
    </button>
    <table
        style="margin-top: 29px"
        class="table"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <thead>
      <tr
          class="text-light"
          style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("branch_sites.name_en") }}</th>
        <th scope="col">{{ $t("branch_sites.name") }}</th>
        <th scope="col">{{ $t("branch_sites.addr") }}</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="site in branch_sites"
          :key="site"
      >
        <td scope="col">{{ site?.name_en }}</td>
        <td scope="col">{{ site?.name }}</td>
        <td scope="col">{{ site?.addr }}</td>
        <td scope="col">
          <button class="btn btn-success" data-bs-toggle="modal" @click="edit(site)" data-bs-target="#edit-account">{{$t("tree.edit")}}</button>
          <button class="btn btn-danger" @click="deleteSite(site)">{{$t("tree.delete")}}</button>
        </td>
      </tr>
      </tbody>
    </table>

  </Layout>

</template>
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";


export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
   data(){
     return {
        branch_sites:[]
     }
   },
  methods:{
    deleteSite(site){
      this.popup.confirm({
        title:"popups.confirm",
        msg:"popups.deletemsg"
      }).then(resp=>{
        if(resp){
          this.http.post("level-one-chart-of-accounts/delete-branch-site",{
            id:site.id
          }).then(() => {
            this.get()
          })
        }
      })
    },
    get(){
      this.http.post("level-one-chart-of-accounts/get-branch-sites",{})
          .then(resp=>{
            console.log(resp.data)
            this.branch_sites = resp.data
          })
    },
    addSite(){
      var data = {
        title: "popups.addBranchSite",
        inputs:[

          {"model":"name",
            "type":"text",
            "label":(this.$t("branch_sites.name"))
          },

          {"model":"name_en",
            "type":"text",
            "label":this.$t("branch_sites.name_en")
          },

          {"model":"addr",
            "type":"text",
            "label":this.$t("branch_sites.addr")
          }
        ],
        buttons: [
          {
            text: "add", closer: true, color: "primary", handler: (obj) => {
              this.http.post("level-one-chart-of-accounts/add-branch-site",obj).then(() => {
                this.get()
              })
            }

          },
          {
            text: "Cancel", closer: true, color: "danger", handler: () => {
              console.log("Like Clicked")
            }
          },
        ]

      }
      this.popup.modal(data)
    },
    edit(site){
      var data = {
        title: "popups.addBranchSite",
        inputs:[

          {"model":"name",
            "type":"text",
            value:site.name,
            "label":(this.$t("branch_sites.name"))
          },

          {"model":"name_en",
            "type":"text",
            value:site.name_en,
            "label":this.$t("branch_sites.name_en")
          },
          {"model":"addr",
            "type":"text",
            value:site.addr,
            "label":this.$t("branch_sites.addr")
          }
        ],
        buttons: [
          {
            text: "edit", closer: true, color: "primary", handler: (obj) => {
              obj.id = site.id
              this.http.post("level-one-chart-of-accounts/edit-branch-site",obj).then(() => {
                this.get()
              })
            }

          },
          {
            text: "Cancel", closer: true, color: "danger", handler: () => {
              console.log("Like Clicked")
            }
          },
        ]

      }
      this.popup.modal(data)
    }

  },
  mounted() {
    this.get()
  }
}
</script>